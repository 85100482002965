import React, { useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, useLocation, Navigate } from 'react-router-dom';
import './assets/styles/css/cssReset.css';
import AdminPage from './pages/adminPage/AdminPage';
import MainPage from './pages/mainPage/MainPage';
import Header from './components/Header/Header';
import FullPage from './features/FullPage'; // FullPage 컴포넌트
import YouTubePlayer from './features/YouTubePlayer';
import Section4_Sub from "./pages/mainPage/section/section4/Section4_Sub";

const App = () => {
    useEffect(() => {
        const intervalId = setInterval(() => {
            // Interval logic (if any)
        }, 2000);
        return () => {
            clearInterval(intervalId);
        };
    }, []);

    const location = useLocation(); // 현재 경로 가져오기

    return (
        <div>
            {/* AdminPage, admin, test 경로가 아닐 때만 Header 표시 */}
            {location.pathname !== '/adminsmicai' && location.pathname !== '/section4_sub' && <Header />}
            <Routes>
                <Route path="/adminsmicai" element={<AdminPage />} />
                <Route path="/" element={
                    <>
                        <FullPage /> {/* 전체 페이지 스크롤 기능 */}
                        <YouTubePlayer /> {/* YouTube 비디오 플레이어 */}
                        <MainPage />
                    </>
                } />
                <Route path="/section4_sub" element={<Section4_Sub />} />
                {/* 모든 잘못된 경로에 대해 MainPage로 리디렉션 */}
                <Route path="*" element={<Navigate to="/" replace />} />
            </Routes>
        </div>
    );
};

// Router를 App 컴포넌트 내부에서 사용
const AppWrapper = () => (
    <Router>
        <App />
    </Router>
);

export default AppWrapper;
