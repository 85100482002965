import axios from "axios";
import authStorage from "../storages/authStorage";

// const baseURL = 'http://192.168.0.5:8912'; // 프로토콜 추가
// const baseURL = 'http://192.168.33.6:8912'; // 로컬 테스트용
const baseURL = process.env.REACT_APP_BASE_URL;


export const api = axios.create({
    baseURL,
    headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json;charset=UTF-8',
    },
    withCredentials:true,
});

api.interceptors.request.use(async (config) => {
    const accessToken = await authStorage.getAccessToken(); // 비동기 처리
    if (accessToken) {
        config.headers.Authorization = `Bearer ${accessToken}`;
    }
    return config;
});


export default api;

export function applyToken(token) {
    api.defaults.headers.common.Authorization = `Bearer ${token}`;
}

export function clearToken(){
    api.defaults.headers.common.Authorization = '';
}
