import React, {useEffect, useState} from "react";
import {Swiper, SwiperClass, SwiperSlide} from "swiper/react";

import "./Section3.css";
import slide1 from '../../../../images/mainpage/section3/product-slide-img-1.png';
import slide2 from '../../../../images/mainpage/section3/product-slide-img-2.png';
import slide3 from '../../../../images/mainpage/section3/product-slide-img-3.png';
import {Navigation, Pagination,} from "swiper/modules";
import {getMachineList} from "../../../../features/mainPage/ServiceFeatures";


const Section3 = () => {

    const MachineListSubmit = async (uses) => {
            try {
                const response = await getMachineList(uses);
            } catch (e) {
                console.log('Error getMachineList', e);
            }
    }

    /*기기명 전송시 해당 기기 정보 */
    const MachineInformation = async ()=>{
        try {
        }catch (e){
            console.log('Error getMachineList', e);
        }
    }


    return (
        <div className="section section3 sec3 " id="section-processing-machine" data-anchor="processing-machine">
            <div className="inner section__inner">
                {/* 페이지 설명 */}
                <div className="subtitle sec3__subtitle">
                    <div className="page-name">processing machine</div>
                    <p className="desc-text desc-text-1">
                        강력한 성능의 설비를 사용해<br/>요청한 제품의 형태를 보다
                        정밀하게 제작합니다.
                    </p>
                </div>

                {/* 페이지 컨텐츠 - section 3 - swiper slide 라이브러리 */}
                <Swiper
                    modules={[Navigation, Pagination]}
                    className="section3-slider"
                    loop={true}
                    speed={1000}
                    pagination={{
                        clickable: true, // 클릭 가능 여부
                        renderBullet: function (index, className) {
                            return '<span class="' + className + '">' + (index + 1) + '</span>'; // 불릿 커스터마이즈
                        },
                    }}
                    navigation={{
                        nextEl: '.swiper-button-next', // 다음 버튼 선택자
                        prevEl: '.swiper-button-prev', // 이전 버튼 선택자
                    }}
                >
                    {/* 슬라이드 컨텐츠 1 */}
                    <SwiperSlide>
                        <img className="machine-img" src={slide1} alt="Hi-Mold 560A"/>
                        {/* 정보 */}
                        <div className="info-table">
                            <div className="info-table-top">
                                <div className="info-text info-text-1">specific</div>
                                <div className="info-text machine-name">Hi-Mold 560A</div>
                            </div>
                            <div className="info-table-bottom">
                                <div className="info-row">
                                    <div className="menu">제조사</div>
                                    <div className="info-text">현대위아</div>
                                </div>
                                <div className="info-row">
                                    <div className="menu">척 사이즈</div>
                                    <div className="info-text">6 inch</div>
                                </div>
                                <div className="info-row">
                                    <div className="menu">최대 가공경</div>
                                    <div className="info-text">350mm</div>
                                </div>
                                <div className="info-row">
                                    <div className="menu">가공길이</div>
                                    <div className="info-text">330mm</div>
                                </div>
                                <div className="info-row">
                                    <div className="menu">용도</div>
                                    <div className="info-text">절삭가공</div>
                                </div>
                            </div>
                        </div>
                    </SwiperSlide>
                    {/* 슬라이드 컨텐츠 2 */}
                    <SwiperSlide>
                        <img src={slide2} className="machine-img" alt="e-mac 280"/>
                        <div className="info-table">
                            <div className="info-table-top">
                                <div className="info-text info-text-1">specific</div>
                                <div className="info-text machine-name">e-mac 280</div>
                            </div>
                            <div className="info-table-bottom">
                                <div className="info-row">
                                    <div className="menu">제조사</div>
                                    <div className="info-text">ENGEL</div>
                                </div>
                                <div className="info-row">
                                    <div className="menu">설계</div>
                                    <div className="info-text">관절식</div>
                                </div>
                                <div className="info-row">
                                    <div className="menu">작업크기</div>
                                    <div className="info-text">730 X 730 mm</div>
                                </div>
                                <div className="info-row">
                                    <div className="menu">폐쇄력</div>
                                    <div className="info-text">&lt; 3,800kN</div>
                                </div>
                                <div className="info-row">
                                    <div className="menu">용도</div>
                                    <div className="info-text">사출성형</div>
                                </div>
                            </div>
                        </div>
                    </SwiperSlide>
                    {/* 슬라이드 컨텐츠 3 */}
                    <SwiperSlide>
                        <img src={slide3} className="machine-img" alt="FS3015"/>
                        <div className="info-table">
                            <div className="info-table-top">
                                <div className="info-text info-text-1">specific</div>
                                <div className="info-text machine-name">FS3015</div>
                            </div>
                            <div className="info-table-bottom">
                                <div className="info-row">
                                    <div className="menu">제조사</div>
                                    <div className="info-text">에이치케이</div>
                                </div>
                                <div className="info-row">
                                    <div className="menu">정밀도</div>
                                    <div className="info-text">±0.1mm/3,000mm</div>
                                </div>
                                <div className="info-row">
                                    <div className="menu">작업크기</div>
                                    <div className="info-text">3,048 X 1,524 mm</div>
                                </div>
                                <div className="info-row">
                                    <div className="menu">이송속도</div>
                                    <div className="info-text">140m/min</div>
                                </div>
                                <div className="info-row">
                                    <div className="menu">용도</div>
                                    <div className="info-text">판재가공</div>
                                </div>
                            </div>
                        </div>
                    </SwiperSlide>
                    <div className="swiper-button-next"></div>
                    <div className="swiper-button-prev"></div>
                </Swiper>
                {/* swiper-button-next, swiper-button-prev 추가 */}

            </div>
        </div>
    );
};

export default Section3;
