import React, { useState, useRef, useEffect } from 'react';
import './MainModal.css'; // CSS 파일 경로
import modalRegist from "../../../images/mainpage/modal/modal_regist.png";
import close from "../../../images/mainpage/modal/close.png";
import modalOptionDown from '../../../images/mainpage/modal/modal-option-down.svg';
import { insertService } from "../../../features/mainPage/ServiceFeatures";
import Loading from "../../../images/mainpage/LoadingIMG.gif";

const ServiceApplicationModal = ({ isOpen, onClose }) => {
    const [isLoading, setIsLoading] = useState(false);
    const [formData, setFormData] = useState({
        lcategory: '',
        scategory: '',
        company: '', // companyNm을 company로 변경
        name: '', // manageNm을 name으로 변경
        phone: '',
        email: '',
        content: '', // inquiries를 content로 변경
        filePath: [],
        whether: true,
    });

    const [charCount, setCharCount] = useState(0);
    const fileInputRef = useRef(null); // 파일 input에 대한 ref
    const [totalFiles, setTotalFiles] = useState([]);

    // 드롭다운 상태 추가
    const [isMainDropdownOpen, setMainDropdownOpen] = useState(false);
    const [isSubDropdownOpen, setSubDropdownOpen] = useState(false);
    const [subOptions, setSubOptions] = useState([]); // 소분류 항목을 저장

    // 대분류와 소분류 코드 매핑
    const lcategoryMapping = {
        '제품 디자인 / 모델링': 'LC01',
        '모델 최적화': 'LC02',
        '시제품 제작': 'LC03',
        '가공/커팅': 'LC04',
    };

    const scategoryMapping = {
        '스케치 기반 3D 모델링': 'SC01',
        '3D 스캐닝': 'SC02',
        '제조/가공 도면 제작': 'SC03',
        '디자인 최적화': 'SC04',
        '금형설계 최적화': 'SC05',
        '금형 제작': 'SC06',
        '제품 사출': 'SC07',
        '3D 프린팅': 'SC08',
        'CNC 가공': 'SC09',
        '레이저 판재 커팅': 'SC10',
    };

    useEffect(() => {
        if (!isOpen) return;

        // 초기화 및 모달 관련 설정
        resetModal();
    }, [isOpen]);

    useEffect(() => {
        const handleKeyDown = (event) => {
            if (event.key === 'Escape') {
                onClose(); // ESC 키가 눌리면 모달 닫기
            }
        };

        // 키보드 이벤트 리스너 추가
        window.addEventListener('keydown', handleKeyDown);

        // 컴포넌트 언마운트 시 이벤트 리스너 제거
        return () => {
            window.removeEventListener('keydown', handleKeyDown);
        };
    }, [onClose]);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleFileChange = (e) => {
        const files = Array.from(e.target.files);
        const combinedFiles = [...totalFiles, ...files];

        if (combinedFiles.length > 3) {
            alert('파일은 최대 3개까지 업로드할 수 있습니다.');
            return;
        }

        setTotalFiles(combinedFiles);
    };

    const handleInquiryInputChange = (e) => {
        setCharCount(e.target.value.length);
        handleChange(e);
    };

    const handleFileRemove = (index) => {
        setTotalFiles(prevFiles => prevFiles.filter((_, i) => i !== index));
    };


    const handleSubmit = async (e) => {
        e.preventDefault();

        // 유효성 검사
        if (!formData.lcategory) {
            alert('신청 서비스 대분류를 선택해주세요.');
            return;
        }
        if (!formData.scategory) {
            alert('신청 서비스 소분류를 선택해주세요.');
            return;
        }
        // if (!formData.company) { // companyNm에서 company로 변경
        //     alert('기업명을 입력해주세요.');
        //     return;
        // }
        if (!formData.name) { // manageNm에서 name으로 변경
            alert('담당자명을 입력해주세요.');
            return;
        }
        if (!/^\d+$/.test(formData.phone) || formData.phone.length < 10 || formData.phone.length > 11) {
            alert('연락처를 다시 입력해주세요.');
            return;
        }
        if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(formData.email)) {
            alert('올바른 이메일 형식을 입력해주세요.');
            return;
        }
        if (!formData.content) { // inquiries에서 content로 변경
            alert('문의 내용을 입력해주세요.');
            return;
        }

        // FormData 객체 생성
        const formDataToSend = new FormData();

        // 서비스 데이터 추가 (코드로 변환)
        // formDataToSend.append('svcCateDepth1', lcategoryMapping[formData.lcategory]); // 대분류 코드 추가
        formDataToSend.append('svcCateDepth1', formData.lcategory); // 대분류 코드 추가
        // formDataToSend.append('svcCateDepth2', scategoryMapping[formData.scategory]); // 소분류 코드 추가
        formDataToSend.append('svcCateDepth2', formData.scategory); // 소분류 코드 추가
        formDataToSend.append('company', formData.company); // companyNm에서 company로 변경
        formDataToSend.append('name', formData.name); // manageNm에서 name으로 변경
        formDataToSend.append('phone', formData.phone);
        formDataToSend.append('email', formData.email);
        formDataToSend.append('content', formData.content); // inquiries에서 content로 변경
        formDataToSend.append('whether', formData.whether ? '1' : '0');  // boolean을 1 또는 0으로 변환

        // 파일 추가 (최대 3개)
        if (totalFiles.length > 0) {
            totalFiles.forEach(file => {
                formDataToSend.append('filePath', file); // filePath는 파일 리스트로 처리
            });
        }

        setIsLoading(true); // 로딩 시작

        try {
            // 데이터 전송
            const responseCode = await insertService(formDataToSend);
            if (responseCode === 200) {
                alert('서비스 신청이 완료되었습니다.');
                onClose(); // 모달 닫기
            } else {
                alert('서비스 신청에 실패했습니다.');
                onClose(); // 모달 닫기
            }
        } catch (error) {
            console.error(error);
            alert('서비스 신청 중 오류가 발생했습니다.');
        }finally {
            setIsLoading(false); // 로딩 종료
            onClose(); // 모달 닫기
        }
    };


    const resetModal = () => {
        setFormData({
            lcategory: '',
            scategory: '',
            company: '',
            name: '',
            phone: '',
            email: '',
            content: '',
            filePath: [],
            whether: 1,
        });
        setTotalFiles([]);
        setCharCount(0);
        setSubOptions([]);  // 소분류 초기화
    };

    // 드롭다운 열기/닫기 함수
    const toggleMainDropdown = () => {
        setMainDropdownOpen(!isMainDropdownOpen);
        if (isSubDropdownOpen) {
            setSubDropdownOpen(false); // 소분류 드롭다운 닫기
        }
    };

    const toggleSubDropdown = () => {
        setSubDropdownOpen(!isSubDropdownOpen);
        if (isMainDropdownOpen) {
            setMainDropdownOpen(false); // 대분류 드롭다운 닫기
        }
    };

    // 대분류 선택 시 소분류 필터링
    const handleMainSelection = (mainCategory) => {
        setFormData({ ...formData, lcategory: mainCategory, scategory: '' });

        // 대분류에 따른 소분류 옵션 설정
        let subCategories = [];
        switch (mainCategory) {
            case '제품 디자인 / 모델링':
                subCategories = ['스케치 기반 3D 모델링', '3D 스캐닝', '제조/가공 도면 제작','기타'];
                break;
            case '모델 최적화':
                subCategories = ['디자인 최적화', '금형설계 최적화','기타'];
                break;
            case '시제품 제작':
                subCategories = ['금형 제작', '제품 사출', '3D 프린팅','기타'];
                break;
            case '가공/커팅':
                subCategories = ['CNC 가공', '레이저 판재 커팅','기타'];
                break;
            case '기타':
                subCategories = ['기타'];
                break;
            default:
                subCategories = [];
        }
        setSubOptions(subCategories);  // 소분류 옵션 설정
        setMainDropdownOpen(false);  // 대분류 드롭다운 닫기
    };

    // 소분류 선택 시 처리
    const handleSubSelection = (subCategory) => {
        setFormData({ ...formData, scategory: subCategory });
        setSubDropdownOpen(false);  // 소분류 드롭다운 닫기
    };

    if (!isOpen) return null; // 모달이 열리지 않으면 아무것도 렌더링하지 않음

    return (
        <div className="modal modal-regist">
            <div className="modal__title">
                <img src={modalRegist} alt="아이콘"/>
                <p className="modal-name">서비스 신청</p>
                <img src={close} alt="닫기버튼" className="icon modal-close" onClick={onClose}/>
            </div>
            <div className="modal-content-wrapper">
                <div className="modal__contents">
                    <div className="modal__note">
                        <span>*</span> 표시는 필수 입력사항 입니다.
                    </div>
                    {isLoading && (
                        <div className="loading-spinner">
                            <img src={Loading}/>
                            서비스 신청중입니다.
                        </div>
                    )}
                    <form onSubmit={handleSubmit}>
                        {/* 대분류 */}
                        <div className="form-group">
                            <label htmlFor="service-main" className="form-name essential">신청 서비스 대분류 선택</label>
                            <div className="user-input drop-down-option main-option" onClick={toggleMainDropdown}>
                                <p style={{color: formData.lcategory ? 'rgb(0, 0, 0)' : 'rgb(153, 153, 153)'}}>
                                    {formData.lcategory || '신청할 서비스를 선택해주세요'}
                                </p>
                                <img src={modalOptionDown} alt="option-open-btn" className="icon chevron-down"/>
                            </div>
                            {isMainDropdownOpen && (
                                <ul className="option-group service-main-options">
                                    <li className="option-item" onClick={() => handleMainSelection('제품 디자인 / 모델링')}>제품
                                        디자인 / 모델링
                                    </li>
                                    <li className="option-item" onClick={() => handleMainSelection('모델 최적화')}>모델 최적화
                                    </li>
                                    <li className="option-item" onClick={() => handleMainSelection('시제품 제작')}>시제품 제작
                                    </li>
                                    <li className="option-item" onClick={() => handleMainSelection('가공/커팅')}>가공/커팅</li>
                                    <li className="option-item" onClick={() => handleMainSelection('기타')}>기타</li>
                                </ul>
                            )}
                        </div>
                        {/* 소분류 */}
                        <div className="form-group">
                            <label htmlFor="service-sub" className="form-name essential">신청 서비스 소분류 선택</label>
                            <div className="user-input drop-down-option sub-option" onClick={toggleSubDropdown}>
                                <p style={{color: formData.scategory ? 'rgb(0, 0, 0)' : 'rgb(153, 153, 153)'}}>
                                    {formData.scategory || '대분류를 먼저 선택해주세요'}
                                </p>
                                <img src={modalOptionDown} alt="option-open-btn" className="icon chevron-down"/>
                            </div>
                            {isSubDropdownOpen && (
                                <ul className="option-group service-sub-options">
                                    {subOptions.map((subOption, index) => (
                                        <li key={index} className="option-item"
                                            onClick={() => handleSubSelection(subOption)}>
                                            {subOption}
                                        </li>
                                    ))}
                                </ul>
                            )}
                        </div>

                        {/* 기업명 */}
                        <div className="form-group">
                            <label htmlFor="company-name" className="form-name ">기업명       [ 기업인 경우에만 작성 ]</label>
                            <div className="user-input">
                                <input type="text" id="company-name" name="company" placeholder="기업명을 입력해주세요"
                                        onChange={handleChange}/>
                                <div className="error-message" id="company-error"></div>
                            </div>
                        </div>
                        {/* 담당자명 */}
                        <div className="form-group">
                            <label htmlFor="person-in-charge" className="form-name essential">신청자명</label>
                            <div className="user-input">
                                <input type="text" id="person-in-charge" name="name"
                                       placeholder="담당자명을 입력해주세요" required onChange={handleChange}/>
                                <div className="error-message" id="person-error"></div>
                            </div>
                        </div>
                        {/* 연락처 */}
                        <div className="form-group">
                            <label htmlFor="phone" className="form-name essential">연락처</label>
                            <div className="user-input">
                                <input type="tel" id="phone" name="phone" placeholder="01012345678 형식으로 입력해주세요"
                                       required onChange={handleChange}/>
                                <div className="error-message" id="contact-error"></div>
                            </div>
                        </div>
                        {/* 이메일 */}
                        <div className="form-group">
                            <label htmlFor="email" className="form-name essential">이메일</label>
                            <div className="user-input">
                                <input type="email" id="email" name="email" placeholder="이메일을 입력해주세요" required
                                       onChange={handleChange}/>
                                <div className="error-message" id="email-error"></div>
                            </div>
                        </div>
                        {/* 문의내용 */}
                        <div className="form-group">
                            <div className="context-group">
                                <label htmlFor="content" className="form-name essential">문의내용</label>
                                <span className="char-count">[ {charCount}/500 ]</span>
                            </div>
                            <div className="user-input textarea-type">
                                <textarea id="content" maxLength="499" name="content" placeholder="문의내용을 입력해주세요" rows="5" required
                                          onChange={handleInquiryInputChange}/>
                            </div>
                        </div>

                        {/* 파일 첨부 */}
                        <div className="form-group file-group">
                            <label className="form-name" htmlFor="file-upload">첨부파일</label>
                            <div className="user-input file file-input">
                                {/* 파일 선택 버튼 */}
                                <button
                                    type="button"
                                    className="custom-file-upload-btn"
                                    onClick={() => fileInputRef.current.click()}
                                >
                                    파일 선택
                                </button>
                                {/* 숨겨진 파일 입력 */}
                                <input
                                    type="file"
                                    id="file-upload"
                                    name="filePath"
                                    ref={fileInputRef}
                                    onChange={handleFileChange}
                                    multiple
                                    style={{display: 'none'}} // 숨김 처리
                                />
                                {/* 선택된 파일 목록 */}
                                <ul className="file-list">
                                    {totalFiles.map((file, index) => (
                                        <li key={index} className="file-item">
                                            {file.name}
                                            <button
                                                type="button"
                                                className="remove-file-btn"
                                                onClick={() => handleFileRemove(index)}
                                            >
                                                &times; {/* 또는 '×' */}
                                            </button>
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        </div>

                        {/* 이용 약관 동의 */}
                        <div className="form-group form-bottom">
                            <div className="form-group checkbox-group">
                                <input
                                    type="checkbox"
                                    id="case-agreement"
                                    name="whether"
                                    checked={formData.whether === 1} // 1이면 체크, 0이면 체크 해제
                                    onChange={(e) => setFormData({...formData, whether: e.target.checked ? 1 : 0})} // true/false를 1/0으로 변환
                                    required
                                />
                                <label htmlFor="case-agreement" className="case-agreement">추후 사례활용에 동의하시겠습니까?</label>
                            </div>
                            <button type="submit" className="btn btn-modal-regist">서비스 신청</button>
                        </div>


                    </form>
                </div>
            </div>
        </div>
    );
};

export default ServiceApplicationModal;
