import React, { useState } from "react";
import AdminPageFeatures from '../../features/adminPage/AdminPageFeatures'; // 기능 부분을 가져옵니다.
import "../../assets/styles/css/admin.css";

const AdminPage = () => {
    const [modalOpen, setModalOpen] = useState(false);
    const [isEditing, setIsEditing] = useState(false);
    const [formData, setFormData] = useState({
        ucTitle: "",
        companyName: "",
        inquiryDt: "",
        svcDt: "",
        showYn: "Y",
        ucContent: "",
        ucImg: null,
        ucImg1: null,
        ucImg2: null,
    });

    const resetForm = () => {
        setFormData({
            ucTitle: "",
            companyName: "",
            inquiryDt: "",
            svcDt: "",
            showYn: "Y",
            ucContent: "",
            ucImg: null,
            ucImg1: null,
            ucImg2: null,
        });
        setIsEditing(false);
    };

    const handleRegisterClick = () => {
        resetForm(); // 새로운 값으로 초기화
        setModalOpen(true); // 모달 열기
    };

    return (
        <div className="wrapper">
            <div className="review-management">
                <div className="page-title">활용사례 관리</div>
                <div className="container">
                    <div className="admin_menu">
                        <div className="btn-regist" onClick={handleRegisterClick}>
                            활용사례 등록하기
                        </div>
                    </div>
                    <div className="table-contents">
                        <AdminPageFeatures
                            modalOpen={modalOpen}
                            toggleModal={() => setModalOpen(prev => !prev)}
                            formData={formData} // formData 전달
                            setFormData={setFormData} // setFormData 전달
                            setIsEditing={setIsEditing} // setIsEditing 전달
                            isEditing={isEditing} // isEditing도 전달
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AdminPage;
