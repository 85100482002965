import React, {useState} from 'react';
// import logo from './logo-large.png'; // 로고 이미지 경로
import logo from './logo-modi.png'; // 로고 이미지 경로
import "./Header.css";
import fabIcon from "../../images/mainpage/fab-icon.png";
import ServiceApplicationModal from "../../pages/mainPage/modal/MainModal";

const Header = () => {

    const [isModalOpen, setModalOpen] = useState(false); // 모달 상태 관리

    const handleOpenModal = () => {
        setModalOpen(true); // 모달 열기
    };

    const handleCloseModal = () => {
        setModalOpen(false); // 모달 닫기
    };

    return (
        <>
            <header>
                <div className="inner header__inner" id="menu">
                    <a href="/">
                        <img src={logo} alt="헤더-smic로고" className="main-logo"/>
                    </a>
                    <div className="gnb">
                        <a href="#main" className="gnb__btn gnb--normal active">main</a>
                    </div>
                    <div className="gnb">
                        <a href="#about-service" className="gnb__btn gnb--normal">about service</a>
                    </div>
                    <div className="gnb">
                        <a href="#processing-machine" className="gnb__btn gnb--normal">보유장비</a>
                    </div>
                    <div className="gnb">
                        <a href="#review" className="gnb__btn gnb--normal">적용사례</a>
                    </div>
                    <div className="gnb">
                        <a href="#contact" className="gnb__btn gnb--normal">contact</a>
                    </div>
                    <div className="gnb">
                        <div className="gnb__btn btn-regist serviceModal" onClick={handleOpenModal}>
                            서비스 신청하기
                        </div>
                    </div>
                </div>
                <div className="fab fab-regist serviceModal" onClick={handleOpenModal}>
                    <img src={fabIcon} alt="서비스신청"/>
                    <p>서비스 신청하기</p>
                </div>

            </header>
            <ServiceApplicationModal isOpen={isModalOpen} onClose={handleCloseModal} /> {/* 모달 */}
        </>
    );
};

export default Header;
