import React from "react";
import "./Section5.css";
import Footer from "../../../../components/Footer/Footer";

const Section5 = () => {
    return (
        <div className="section sec5">
            <div className="inner section__inner sec5__inner">
                {/* 페이지 설명 */}
                <div className="subtitle sec5__subtitle">
                    <div className="page-name">contact</div>
                </div>
                <div className="sec5__content">
                    <div className="contact-box">
                        <p className="contact-text">오시는 길</p>
                        <p className="contact-text address">
                            <span>주소</span>
                            <span>경기도 안산시 상록구 해양 3로 17 디지털전환허브 1~5층 스마트제조혁신센터</span>
                        </p>
                    </div>
                    <div className="map-wrapper">
                        {/* 구글 지도 iframe */}
                        <iframe
                            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3174.401355844679!2d126.82586487639632!3d37.285620540185576!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x357b6f1686de13c1%3A0xaae22ba8ca8dcd8d!2z6rK96riw7YWM7YGs64W47YyM7YGsIOuUlOyngO2EuOyghO2ZmO2XiOu4jA!5e0!3m2!1sko!2skr!4v1727772535433!5m2!1sko!2skr"
                            width="100%"
                            // 높이값 수정 241126
                            height="100%"
                            style={{ border: 0 }}
                            allowFullScreen=""
                            loading="lazy"
                            referrerPolicy="no-referrer-when-downgrade"
                        ></iframe>
                    </div>
                </div>
            </div>
            {/* 푸터영역 */}
            <Footer/>
        </div>
    );
};

export default Section5;
