import React from 'react';
import {Swiper, SwiperSlide} from 'swiper/react';
import 'swiper/swiper-bundle.css';

import video1 from '../../../../images/video/제품 디자인,모델링 서비스.mp4'
import video2 from '../../../../images/video/AI 기반 모델 최적화 서비스.mp4'
import video3 from '../../../../images/video/시제품 제작 서비스.mp4'
import video6 from '../../../../images/video/제품 가공 커팅 서비스merge.mp4';

import 'swiper/css/pagination';
import './Section1.css';
import {Navigation, Pagination} from 'swiper/modules';

const Section1 = () => {
    return (
        <div className="section active" id="section-main" data-anchor="main">
            <div className={"mainSwiper"}>
                <Swiper
                    modules={[Navigation, Pagination]}
                    className="mainSwiper"
                    spaceBetween={50}
                    slidesPerView={1}
                    speed={1000}
                    loop={true}
                    pagination={{
                        clickable: true,
                    }}
                    navigation={{
                        nextEl: '.swiper-button-next',
                        prevEl: '.swiper-button-prev',
                    }}
                >
                    {/* 슬라이드 컨텐츠 1 */}
                    <SwiperSlide>
                        <div className="slide-contents">
                            <video
                                src={video1}
                                autoPlay
                                loop
                                muted
                                className="background-video"
                            />
                        </div>
                        <div className="slide-text-box inner main-slide-text-inner">
                            <p className="text-box text-box__top">제품 디자인/모델링 서비스</p>
                            <p className="text-box text-box__bottom">
                                스케치된 기능과 구조에 대한 형상화부터 실 대상물에 대한 3D
                                스캔, 제조 혹은 가공을 위한 도면 제작까지, 제품 디자인과
                                모델링에 대한 포괄적인 서비스를 제공합니다.
                            </p>
                        </div>
                    </SwiperSlide>
                    {/* 슬라이드 컨텐츠 2 */}
                    <SwiperSlide>
                        <div className="slide-contents">
                            <video
                                src={video2}
                                autoPlay
                                loop
                                muted
                                className="background-video"
                            />
                        </div>
                        <div className="slide-text-box inner main-slide-text-inner">
                            <p className="text-box text-box__top">AI 기반 설계 지원 서비스</p>
                            <p className="text-box text-box__bottom">
                                기존 모델의 기능 및 성능을 유지하며 최적의 Volume, 구조, 재질
                                추천과 선설계를 통해 정확한 견적을 도출하는 서비스를
                                제공합니다.
                            </p>
                        </div>
                    </SwiperSlide>
                    {/* 슬라이드 컨텐츠 3 */}
                    <SwiperSlide>
                        <div className="slide-contents">
                            <video
                                src={video3}
                                autoPlay
                                loop
                                muted
                                className="background-video"
                            />
                        </div>
                        <div className="slide-text-box inner main-slide-text-inner">
                            <p className="text-box text-box__top">시제품 제작 서비스</p>
                            <p className="text-box text-box__bottom">
                                구상한 모델의 대량생산을 위해 필요한 금형제작부터 금형을
                                활용한 제품 사출까지 시제품 제작을 위한 포괄적인 서비스를
                                제공합니다.
                            </p>
                        </div>
                    </SwiperSlide>
                    {/* 슬라이드 컨텐츠 4 */}
                    <SwiperSlide>
                        <div className="slide-contents">
                            <video
                                src={video6}
                                autoPlay
                                loop
                                muted
                                className="background-video"
                            />
                        </div>
                        <div className="slide-text-box inner main-slide-text-inner">
                            <p className="text-box text-box__top">제품 가공/커팅 서비스</p>
                            <p className="text-box text-box__bottom">
                                High Performance 절삭 가공기 및 판재 커팅기를 통해 원하는
                                형태의 가공 및 판재 커팅 서비스를 제공합니다.
                            </p>
                        </div>
                    </SwiperSlide>
                    <div className="swiper-button-next"></div>
                    <div className="swiper-button-prev"></div>
                </Swiper>
            </div>
        </div>
    );
};

export default Section1;
