import React from "react";
import api from "../../api/api";
import {Axios, AxiosError, isAxiosError} from "axios";

const commonURL = '/usecase';

/*메인페이지 서비스 등록 */
/* 메인페이지 서비스 등록 */
export const insertService = async (formDataToSend) => {
    try {
        // 파일이 존재하는지 확인
        const files = formDataToSend.getAll("filePath");
        const fileUrls = []; // 파일 URL을 저장할 배열

        if (files.length > 0) {
            for (let i = 0; i < files.length; i++) {
                const file = files[i];
                const singleFileData = new FormData();
                singleFileData.append("file", file);

                // 파일 업로드 요청
                const uploadResponse = await api.post(`/file/fileupload`, singleFileData, {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                    },
                });

                // 파일 정보 추가
                const fileData = uploadResponse.data.data;
                const fileUrl = `${fileData.fileDomain}${fileData.filePath}`;
                fileUrls.push(fileUrl); // 생성한 URL을 배열에 추가
            }

            // 각 URL을 formDataToSend에 추가
            fileUrls.forEach((url, index) => {
                formDataToSend.append(`fileUrl${index + 1}`, url); // fileUrl1, fileUrl2, fileUrl3
            });
        }

        // 나머지 데이터 전송
        const finalResponse = await api.post(`/email/send`, formDataToSend);
        return finalResponse.data.resCode;

    } catch (error) {
        if (isAxiosError(error)) {
            throw new Error(error.response.data.message || 'insertService failed');
        }
        throw new Error('An unexpected error occurred');
    }
};



/*메인페이지 서비스 후기(활용사례 비로그인용 API 호출)*/
export const getServiceReview = async (page = 1, size = 10) => {
    try {
        // 액세스 토큰을 가져옵니다.
        const response = await api.get(`${commonURL}`, {
            params: {
                page: page, // 페이지 번호
                size: size  // 페이지 크기
            }
        });
        return response.data; // 서버에서 반환된 데이터
    } catch (error) {
        if (isAxiosError(error)) {
            throw new Error(error.response.data.message || 'GetUseCaseList failed');
        }
        throw new Error('getServiceReview An unexpected error occurred');
    }
};

/*기계정보 조회 */
export const getMachineList = async (uses) => {
    try {
        const response = await api.get(`${commonURL}/machine`,);
        return response.data;
    } catch (error) {
        if (isAxiosError(error)) {
            throw new Error(error.response?.data?.message || 'GetMachineList failed'); // 안전한 접근
        }
        throw new Error('An unexpected error occurred');
    }
};