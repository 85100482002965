import React, { useEffect, useState } from "react";
import { getList } from "../usecase/UseCaseSend"; // API 호출 함수 가져오기
import ReactPaginate from "react-paginate"; // react-paginate 임포트
import pagePrev from '../../images/icons/pagi-prev.png';
import pageNext from '../../images/icons/pagi-next.png';

const ReviewTable = ({ handleRowClick, fetchReviews }) => {
    const [reviews, setReviews] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [currentPage, setCurrentPage] = useState(0); // 페이지 인덱스는 0부터 시작
    const size = 15; // 페이지당 항목 수
    const [totalPages, setTotalPages] = useState(0);

    const fetchReviewsData = async (page) => {
        try {
            const data = await getList(page + 1, size); // 페이지는 1부터 시작하므로 +1
            setReviews(data.data.usecaseList);
            setTotalPages(data.data.totalPages);
        } catch (err) {
            setError(err.message);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchReviewsData(currentPage);
    }, [currentPage]);

    const handlePageClick = (data) => {
        setCurrentPage(data.selected); // react-paginate에서 반환하는 인덱스 사용
    };

    if (loading) {
        return <div>Loading...</div>; // 로딩 중 표시
    }

    if (error) {
        return <div>Error: {error}</div>; // 에러 발생 시 표시
    }


    return (
        <>
            <table className="table-review-management">
                <thead>
                <tr>
                    <th className="cell cell-num">No</th>
                    <th className="cell cell-title">제목</th>
                    <th className="cell cell-company">회사명</th>
                    <th className="cell cell-date cell-inquiry-date">문의일</th>
                    <th className="cell cell-date cell-end-date">서비스 완료일</th>
                    <th className="cell cell-visibility">노출 설정</th>
                    <th className="cell cell-date cell-post-date">등록일</th>
                </tr>
                </thead>
                <tbody>
                {reviews.map((review, index) => (
                    <tr key={review.seq} onClick={() => handleRowClick(review)}>
                        <td className="cell cell-num">{index + 1 + currentPage * size}</td>
                        <td className="cell cell-title">{review.ucTitle}</td>
                        <td className="cell cell-company">{review.companyName}</td>
                        <td className="cell cell-date cell-inquiry-date">{review.inquiryDt}</td>
                        <td className="cell cell-date cell-end-date">{review.svcDt}</td>
                        <td className="cell cell-visibility">{review.showYn}</td>
                        <td className="cell cell-date cell-post-date">{review.regDt}</td>
                    </tr>
                ))}
                </tbody>
            </table>
            <ReactPaginate
                previousLabel={<img src={pagePrev}/>}
                nextLabel={<img src={pageNext}/>}
                breakLabel={"..."}
                pageCount={totalPages}
                marginPagesDisplayed={2}
                pageRangeDisplayed={5}
                onPageChange={handlePageClick}
                containerClassName={"pagination"}
                activeClassName={"active"}
                pageClassName={"pagi-btn pagi-num"}
                previousClassName={"pagi-btn pagi-prev"}
                nextClassName={"pagi-btn pagi-next"}
                breakClassName={"pagi-btn"}
                disabledClassName={"disabled"}
            />
        </>
    );
};

export default ReviewTable;
