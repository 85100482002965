import React, { useEffect } from "react";
import fullpage from "fullpage.js";
import "fullpage.js/dist/fullpage.css";

/*fullpage.css line 247 overflow y 주석처리 */
const FullPage = () => {
    useEffect(() => {
        const fullpageInstance = new fullpage("#fullpage", {
            licenseKey: "DM7LI-6FQEJ-I16B6-N2H38-WSUSN",
            menu: "#menu",
            lockAnchors: false,
            onScrollOverflow: true,
            isNormalScrollElement: ".review-content .review__context",
            anchors: [
                "main",
                "about-service",
                "processing-machine",
                "review",
                "contact",
            ],
            navigation: true,
            navigationPosition: "right",
            navigationTooltips: [
                "Main",
                "About Service",
                "Processing Machine",
                "서비스 후기",
                "오시는 길",
            ],
            scrollingSpeed: 700,
            autoScrolling: true,
            showActiveTooltip: false,
            slidesNavigation: false, // 슬라이드 네비게이션 점(dot) 활성화
            slidesNavPosition: "bottom", // 네비게이션 점의 위치
            css3: true,
            fitToSectionDelay: 700,
            fitToSection: true,
            scrollBar: false,
            easing: "easeInOutCubic",
            easingcss3: "ease",
            loopBottom: false,
            loopTop: false,
            loopHorizontal: true,
            continuousVertical: false,
            continuousHorizontal: false,
            scrollHorizontally: false,
            interlockedSlides: false,
            dragAndMove: false,
            offsetSections: false,
            resetSliders: false,
            fadingEffect: false,
            normalScrollElements: "#element1, .element2, .modal",
            scrollOverflow: false,
            scrollOverflowMacStyle: false,
            scrollOverflowReset: false,
            touchSensitivity: 15,
            bigSectionsDestination: null,
            keyboardScrolling: true,
            animateAnchor: true,
            recordHistory: true,

            // 디자인 관련 옵션
            controlArrows: true,
            controlArrowsHTML: [
                '<div class="fp-arrow"></div>',
                '<div class="fp-arrow"></div>',
            ],
            verticalCentered: true,
            paddingTop: "4.75rem",
            fixedElements: "#header, .footer",
            responsiveWidth: 0,
            responsiveHeight: 0,
            responsiveSlides: false,
            parallax: false,
            parallaxOptions: {
                type: "reveal",
                percentage: 62,
                property: "translate",
            },
            dropEffect: false,
            dropEffectOptions: { speed: 2300, color: "#F82F4D", zIndex: 9999 },
            waterEffect: false,
            waterEffectOptions: { animateContent: true, animateOnMouseMove: true },
            cards: false,
            cardsOptions: {
                perspective: 100,
                fadeContent: true,
                fadeBackground: true,
            },

            // 맞춤 선택자
            sectionSelector: ".section",
            slideSelector: ".slide",

            lazyLoading: true,
            lazyLoadThreshold: 0,
            observer: true,

            // 섹션이 로드된 후 실행
            afterLoad: function (origin, destination) {
                const activeIndex = destination.index; // 활성화된 섹션의 인덱스

                // 메뉴의 active 클래스 업데이트
                const menuItems = document.querySelectorAll(".gnb__btn.gnb--normal");
                menuItems.forEach((item, index) => {
                    if (index === activeIndex) {
                        item.classList.add("active");
                    } else {
                        item.classList.remove("active");
                    }
                });

                // 첫 번째 섹션의 비디오 재생
                if (activeIndex === 0) {
                    const videos = document.querySelectorAll('#section-main video');
                    videos.forEach((video) => {
                        if (video.paused) video.play(); // 비디오 재생
                    });
                }
            },

            // 섹션을 떠날 때 실행
            onLeave: function (origin, destination) {
                const leavingIndex = origin.index; // 떠나는 섹션의 인덱스

                // 첫 번째 섹션의 비디오 멈춤
                if (leavingIndex === 0) {
                    const videos = document.querySelectorAll('#section-main video');
                    videos.forEach((video) => {
                        if (!video.paused) video.pause(); // 비디오 멈춤
                    });
                }
            },

            // Made with fullPage.js 메시지 비활성화
            credits: {
                enabled: false,
                label: "Made with fullPage.js",
                position: "right",
            },
        });
        return () => {
            if (fullpageInstance && typeof fullpageInstance.destroy === "function") {
                fullpageInstance.destroy("all"); // fullpage.js 인스턴스를 제거
            }
        };
    }, []);

    return null; // UI를 렌더링하지 않음
};

export default FullPage;
