import React, {useState} from "react";
import Modal from '../adminPage/Modal';
import ReviewTable from './ReviewTable';
import { DeleteUseCase } from "../usecase/UseCaseSend";

const AdminPageFeatures = ({ modalOpen, toggleModal, formData, setFormData, setIsEditing, isEditing }) => {
    const [reviews, setReviews] = useState([
        // 초기 리뷰 데이터...
    ]);

    // 테이블 행 클릭 시 데이터 편집을 위한 함수
    const handleRowClick = (review) => {
        setFormData({
            ucTitle: review.ucTitle,
            companyName: review.companyName,
            inquiryDt: review.inquiryDt,
            svcDt: review.svcDt,
            showYn: review.showYn,
            ucContent: review.ucContent,
            ucImg: null, // 파일 필드 초기화
            ucImg1: null,
            ucImg2: null,
            seq: review.seq, // seq 값 추가
        });
        setIsEditing(true);
        toggleModal(); // 모달 열기
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };

    const handleFileChange = (e) => {
        const { name, files } = e.target;
        setFormData({
            ...formData,
            [name]: files[0],
        });
    };

    const handleDelete = async (seq) => {
        try {
            const result = await DeleteUseCase(seq);
            if (result === 200) {
                alert("삭제에 성공했습니다.");
                toggleModal(); // 모달 닫기
                window.location.reload(); // 페이지 새로고침
            }
        } catch (error) {
            console.error("Failed to delete:", error.message);
        }
    };

    return (
        <>
            <ReviewTable reviews={reviews} handleRowClick={handleRowClick} />
            <Modal
                modalOpen={modalOpen}
                isEditing={isEditing}
                formData={formData}
                handleChange={handleChange}
                handleFileChange={handleFileChange}
                handleDelete={handleDelete}
                toggleModal={toggleModal} // 모달 토글 함수 전달
            />
        </>
    );
};

export default AdminPageFeatures;
