import React, { useState, useEffect } from 'react';
import YouTubePlayer from '../../features/YouTubePlayer';
import Section1 from './section/section1/Section1';
import Section2 from './section/section2/Section2';
import Section3 from './section/section3/Section3';
import Section4 from "./section/section4/Section4";
import Section5 from "./section/section5/Section5";
import './MainPageCss.css';

const MainPage = () => {


    return (
        <div id="fullpage" className="fullpage-wrapper">
            {/*<YouTubePlayer />*/}
            <Section1 />
            <Section2 />
            <Section3 />
            <Section4 />
            <Section5 />
        </div>
    );
};

export default MainPage;
