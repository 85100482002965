import React, {useEffect, useRef, useState} from "react";
import reviewIcon from '../../../../images/mainpage/section4/icon-review.png';
import userIcon from '../../../../images/mainpage/section4/icon-review-user.png';
import heartIcon from '../../../../images/mainpage/section4/icon-review-heart.png';
import reviewModal from '../../../../images/mainpage/section4/modal_review.png';
import close from "../../../../images/mainpage/modal/close.png";
import {Swiper, SwiperSlide} from 'swiper/react';
import "./Section4.css";
import {Navigation, Pagination} from "swiper/modules";
import {getServiceReview} from "../../../../features/mainPage/ServiceFeatures";

const Section4 = () => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [selectedReview, setSelectedReview] = useState(null);
    const [visibleReviews, setVisibleReviews] = useState(10); // 기본 10개의 리뷰 카드
    const [reviews, setReviews] = useState([]); // 리뷰 리스트 상태 추가
    const wrapperRef = useRef(null);

    useEffect(() => {
        const fetchReviews = async () => {
            try {
                const response = await getServiceReview();
                const reviewList = response.data.usecaseList.map(review => ({
                    id: review.seq,
                    title: review.ucTitle,
                    content: review.ucContent,
                    companyName: review.companyName,
                    images: [review.ucImg, review.ucImg1, review.ucImg2].filter(Boolean), // ucImg, ucImg1, ucImg2 사용
                }));
                setReviews(reviewList);
            } catch (error) {
                console.error('Error fetching reviews:', error);
            }
        };
        fetchReviews();
    }, []);


    // 화면 크기에 따라 보여줄 카드 수 조정
    const adjustReviewCards = () => {
        const screenWidth = window.innerWidth;
        let visibleCards = 10;
        wrapperRef.current.style.width = "80%";
        // 화면 크기에 따라 보여줄 카드 수 설정 내용 변경
        if (screenWidth > 1440 && screenWidth < 1920) {
            visibleCards = 8;
        } else if (screenWidth > 1023 && screenWidth <= 1440) {
            visibleCards = 5;
            if (wrapperRef.current) {
                wrapperRef.current.style.width = "100%"; // wrapper의 너비 설정
            }
            // 241127 수정
        } else if (screenWidth >= 753 && screenWidth <= 1023) {
            visibleCards = 4
            if (wrapperRef.current) {
                wrapperRef.current.style.width = "70%"; // wrapper의 너비 설정
            }
        }
        // 241127 수정
        else if (screenWidth < 753) {
            visibleCards = 2;
        } else {
            wrapperRef.current.style.width = "81.25rem"
        }

        setVisibleReviews(visibleCards);
    };

    // 화면 크기 변경 감지
    useEffect(() => {
        adjustReviewCards(); // 처음 렌더링될 때 한 번 실행

        window.addEventListener("resize", adjustReviewCards); // 화면 크기 변경 시 실행
        return () => window.removeEventListener("resize", adjustReviewCards); // 이벤트 클린업
    }, []);

    const handleReviewClick = (review) => {
        setSelectedReview(review);
        setIsModalOpen(true);
    };

    const closeModal = () => {
        setIsModalOpen(false);
        setSelectedReview(null);
    };

    useEffect(() => {
        const handleKeyDown = (event) => {
            if (event.key === 'Escape') {
                closeModal(); // ESC 키가 눌리면 모달 닫기
            }
        };

        // 키보드 이벤트 리스너 추가
        window.addEventListener('keydown', handleKeyDown);

        // 컴포넌트 언마운트 시 이벤트 리스너 제거
        return () => {
            window.removeEventListener('keydown', handleKeyDown);
        };
    },);

    const maskCompanyName = (companyName) => {
        if (!companyName) return "";
        return companyName.charAt(0) + "*".repeat(3);
    };

    const maskTitle = (title) => {
        if (!title) return "";
        return title.length > 15 ? title.slice(0, 15) + "..." : title;
    };

    const maskContent = (content) => {
        if (!content) return "";
        return content.length > 20 ? content.slice(0, 20) + "..." : content;
    };

    return (
        <div className="section sec4">
            <div className="inner section__inner sec4__inner">
                <div className="subtitle sec4__subtitle">
                    <img src={reviewIcon} alt="서비스후기"/>
                    <div className="desc-text desc-text-1">서비스 후기</div>
                </div>
                <div className="sec4__contents">
                    <div className="review-wrapper" ref={wrapperRef}>
                        {reviews.length === 0 ? (
                            <div className={'sec4_noData'}>
                                <p className="no-reviews-message">등록된 후기가 없습니다.</p>
                            </div>
                        ) : (
                            reviews.slice(0, visibleReviews).map(review => (
                                <div className="review-card" key={review.id} onClick={() => handleReviewClick(review)}>
                                    <div
                                        className="review__thumbnail"
                                        style={{ backgroundImage: `url(${review.images[0]})` }}
                                    ></div>
                                    <div className="review__bottom">
                                        <div className="review__company">
                                            <div className="company">
                                                <img src={userIcon} className="user" alt="리뷰-유저이미지"/>
                                                <p className="company-name">{maskCompanyName(review.companyName)}</p>
                                            </div>
                                            <img src={heartIcon} className="heart" alt="좋아요"/>
                                        </div>
                                        <div className="review-content">
                                            <div className="review__title">{maskTitle(review.title)}</div>
                                            <div className="review__context">{review.content}</div>
                                        </div>
                                    </div>
                                </div>
                            ))
                        )}
                    </div>

                    <a href={'/section4_sub'} className="btn more-btn">
                        <p>더 많은 후기 보러가기</p>
                        <p className="arrow">-></p>
                    </a>
                </div>
            </div>


            {isModalOpen && (
                <div className="modal modal-review">
                    <div className="modal__title">
                        <img src={reviewModal} alt="아이콘"/>
                        <p className="modal-name">서비스 후기</p>
                        <img src={close} alt="닫기버튼" className="icon modal-close" onClick={closeModal}/>
                    </div>
                    <div className="modal-content-wrapper">
                        <div className="reviewSwiper">
                            <Swiper
                                modules={[Navigation, Pagination]}
                                className="section4modal-slider"
                                loop={true}
                                navigation={{
                                    nextEl: '.swiper-button-next',
                                    prevEl: '.swiper-button-prev',
                                }}
                            >
                                <div className="swiper-wrapper">
                                    {selectedReview?.images.map((image, index) => (
                                        <SwiperSlide key={index}>
                                            <img src={image} alt={`Review slide ${index + 1}`}/>
                                        </SwiperSlide>
                                    ))}
                                </div>
                                <div className="swiper-button-prev"></div>
                                <div className="swiper-button-next"></div>
                            </Swiper>
                        </div>

                        <div className="review__details">
                            <div className="review__company">
                                <img src={userIcon} className="user" alt="리뷰-유저이미지"/>
                                <p className="company-name">{maskCompanyName(selectedReview.companyName)}</p>
                            </div>
                            <div className="review-content">
                                <div className="review__title">{selectedReview.title}</div> {/* ucTitle로 수정 */}
                                <div className="review__context">{selectedReview.content}</div> {/* ucContent로 수정 */}
                            </div>
                        </div>
                    </div>
                </div>
            )}

        </div>
    );
};

export default Section4;
