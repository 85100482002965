import React, { useEffect } from 'react';

let player1, player2, player3, player4;

const YouTubePlayer = () => {
    useEffect(() => {
        const tag = document.createElement('script');
        tag.src = "https://www.youtube.com/iframe_api";
        const firstScriptTag = document.getElementsByTagName('script')[0];
        firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);

        window.onYouTubeIframeAPIReady = () => {
            player1 = new window.YT.Player('player1', {
                height: '360',
                width: '640',
                videoId: 'SNXOUl59Kak',
                playerVars: {
                    autoplay: 1, // 자동 재생 활성화
                    mute: 1, // 음소거 활성화
                    controls: 0,
                    rel: 0,
                    loop: 1,
                    modestbranding: 1,
                    playlist: 'SNXOUl59Kak'
                },
                events: {
                    'onReady': (event) => event.target.playVideo() // 준비 완료되면 재생
                }
            });

            player2 = new window.YT.Player('player2', {
                height: '360',
                width: '640',
                videoId: 'SNXOUl59Kak',
                playerVars: {
                    autoplay: 1,
                    mute: 1,
                    controls: 0,
                    rel: 0,
                    loop: 1,
                    modestbranding: 1,
                    playlist: 'SNXOUl59Kak'
                },
                events: {
                    'onReady': (event) => event.target.playVideo()
                }
            });

            player3 = new window.YT.Player('player3', {
                height: '360',
                width: '640',
                videoId: 'SNXOUl59Kak',
                playerVars: {
                    autoplay: 1,
                    mute: 1,
                    controls: 0,
                    rel: 0,
                    loop: 1,
                    modestbranding: 1,
                    playlist: 'SNXOUl59Kak'
                },
                events: {
                    'onReady': (event) => event.target.playVideo()
                }
            });

            player4 = new window.YT.Player('player4', {
                height: '360',
                width: '640',
                videoId: 'SNXOUl59Kak',
                playerVars: {
                    autoplay: 1,
                    mute: 1,
                    controls: 0,
                    rel: 0,
                    loop: 1,
                    modestbranding: 1,
                    playlist: 'SNXOUl59Kak'
                },
                events: {
                    'onReady': (event) => event.target.playVideo()
                }
            });
        };
    }, []);

    return null; // UI는 렌더링하지 않음
};

export default YouTubePlayer;
