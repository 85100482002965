import React, {useEffect, useRef, useState} from "react";
import reviewIcon from '../../../../images/icons/icon-review.png';
import userIcon from '../../../../images/icons/icon-review-user.png';
import heartIcon from '../../../../images/icons/icon-review-heart.png';
import pagiPrevIcon from '../../../../images/icons/pagi-prev.png';
import pagiNextIcon from '../../../../images/icons/pagi-next.png';
import {getServiceReview} from "../../../../features/mainPage/ServiceFeatures"; // 리뷰 데이터 가져오기
import './Section4_Sub.css';
import reviewModal from "../../../../images/mainpage/section4/modal_review.png";
import close from "../../../../images/mainpage/modal/close.png";
import {Swiper, SwiperSlide} from "swiper/react";
import {Navigation, Pagination} from "swiper/modules";
import ReactPaginate from "react-paginate"; // ReactPaginate 임포트

const Section4_Sub = () => {
    const [reviews, setReviews] = useState([]); // 리뷰 상태 추가
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [selectedReview, setSelectedReview] = useState(null);
    const [currentPage, setCurrentPage] = useState(0); // 현재 페이지 상태
    const [totalPages, setTotalPages] = useState(0); // 총 페이지 수
    const [visibleReviews, setVisibleReviews] = useState(10); // 기본 10개의 리뷰 카드
    const size = 10; // 페이지당 리뷰 수
    const wrapperRef = useRef(null);

    useEffect(() => {
        const fetchReviews = async () => {
            try {
                const response = await getServiceReview(currentPage + 1, size); // 현재 페이지와 페이지 크기 전달
                if (response.resCode === 200) {
                    const reviewList = response.data.usecaseList.map(review => ({
                        id: review.seq,
                        title: review.ucTitle,
                        content: review.ucContent,
                        companyName: review.companyName,
                        images: [review.ucImg, review.ucImg1, review.ucImg2].filter(Boolean),
                    }));
                    setReviews(reviewList);
                    setTotalPages(Math.ceil(response.data.totalCount / size)); // 총 페이지 수 설정
                } else {
                    console.error('Failed to fetch reviews:', response.message);
                }
            } catch (error) {
                console.error('Error fetching reviews:', error);
            }
        };

        fetchReviews();
    }, [currentPage]); // currentPage 변경 시 다시 호출

    const handleReviewClick = (review) => {
        setSelectedReview(review);
        setIsModalOpen(true);
    };

    const closeModal = () => {
        setIsModalOpen(false);
        setSelectedReview(null);
    };

    useEffect(() => {
        const handleKeyDown = (event) => {
            if (event.key === 'Escape') {
                closeModal(); // ESC 키가 눌리면 모달 닫기
            }
        };

        // 키보드 이벤트 리스너 추가
        window.addEventListener('keydown', handleKeyDown);

        // 컴포넌트 언마운트 시 이벤트 리스너 제거
        return () => {
            window.removeEventListener('keydown', handleKeyDown);
        };
    }, []);

    const handlePageClick = (data) => {
        setCurrentPage(data.selected); // 페이지 변경 시 현재 페이지 업데이트
    };

    // 화면 크기에 따라 보여줄 카드 수 조정
    const adjustReviewCards = () => {
        const screenWidth = window.innerWidth;
        let visibleCards = 10;
        // 화면 크기에 따라 보여줄 카드 수 설정 내용 변경
        if (screenWidth > 1440 && screenWidth < 1920) {
            visibleCards = 10;
        } else if (screenWidth > 1023 && screenWidth <= 1440) {
            visibleCards =8;
            if (wrapperRef.current) {
                wrapperRef.current.style.width = "80%"; // wrapper의 너비 설정
            }

            // 241129 수정
        } else if (screenWidth > 753 && screenWidth <= 1023) {
            visibleCards = 4;
        }

        // 241129 수정
        else if (screenWidth <= 753) {
            visibleCards = 2;
        } else {
            wrapperRef.current.style.width = "80%";
        }

        setVisibleReviews(visibleCards);
    };

    // 화면 크기 변경 감지
    useEffect(() => {
        adjustReviewCards(); // 처음 렌더링될 때 한 번 실행

        window.addEventListener("resize", adjustReviewCards); // 화면 크기 변경 시 실행
        return () => window.removeEventListener("resize", adjustReviewCards); // 이벤트 클린업
    }, []);


    const maskCompanyName = (companyName) => {
        if (!companyName) return "";
        return companyName.charAt(0) + "*".repeat(3);
    };

    const maskTitle = (title) => {
        if (!title) return "";
        return title.length > 15 ? title.slice(0, 15) + "..." : title;
    };

    const maskContent = (content) => {
        if (!content) return "";
        return content.length > 20 ? content.slice(0, 20) + "..." : content;
    };

    return (
        <div className="sec4-sub sub-page-wrapper">
            <div className="sub-inner sub-sec4-sub__inner">
                <div className="sub-subtitle sub-sec4-sub__subtitle">
                    <a href="/#review" className="sub-gnb__btn sub-btn-regist">
                        돌아가기
                    </a>
                    <div className="sub-page-title">
                        <img src={reviewIcon} alt="서비스후기"/>
                        <div className="sub-name">서비스 후기</div>
                    </div>
                </div>
                <div className="sub-sec4-sub__contents">
                    <div className="sub-review-wrapper" ref={wrapperRef}>
                        {reviews.length === 0 ? (
                            <div className={'sub-sec4-noData'}>
                                <p className="no-reviews-message">등록된 후기가 없습니다.</p>
                            </div>
                        ) : (
                            reviews.slice(0, visibleReviews).map(review => ( // 보여줄 카드 수 반영
                                <div
                                    className="sub-review-card"
                                    key={review.id}
                                    onClick={() => handleReviewClick(review)}>
                                    <div
                                        className="sub-review__thumbnail"
                                        style={{
                                            backgroundImage: `url(${review.images[0] || ""})`,
                                        }} // 썸네일 설정
                                    ></div>
                                    <div className="sub-review__bottom">
                                        <div className="sub-review__company">
                                            <div className="sub-company">
                                                <img src={userIcon} className="sub-user" alt="리뷰-유저이미지"/>
                                                <p className="sub-company-name">{maskCompanyName(review.companyName)}</p>
                                            </div>
                                            <img src={heartIcon} className="sub-heart" alt="좋아요"/>
                                        </div>
                                        <div className="sub-review-content">
                                            <div className="sub-review__title">{maskTitle(review.title)}</div>
                                            <div className="sub-review__context">{review.content}</div>
                                        </div>
                                    </div>
                                </div>
                            ))
                        )}
                    </div>

                    {totalPages > 1 && (
                        <div className="sub-pagination">
                            <ReactPaginate
                                previousLabel={<img src={pagiPrevIcon} alt="pagination-btn-prev"/>}
                                nextLabel={<img src={pagiNextIcon} alt="pagination-btn-next"/>}
                                breakLabel={"..."}
                                pageCount={totalPages}
                                marginPagesDisplayed={2}
                                pageRangeDisplayed={5}
                                onPageChange={handlePageClick}
                                containerClassName={"pagination"}
                                activeClassName={"active"}
                                pageClassName={"pagi-btn pagi-num"}
                                previousClassName={"pagi-btn pagi-prev"}
                                nextClassName={"pagi-btn pagi-next"}
                                breakClassName={"pagi-btn"}
                                disabledClassName={"disabled"}
                            />
                        </div>
                    )}
                </div>
            </div>

            {isModalOpen && (
                <div className="modal modal-review">
                    <div className="modal__title">
                        <img src={reviewModal} alt="아이콘"/>
                        <p className="modal-name">서비스 후기</p>
                        <img src={close} alt="닫기버튼" className="icon modal-close" onClick={closeModal}/>
                    </div>
                    <div className="modal-content-wrapper">
                        <div className="reviewSwiper">
                            <Swiper
                                modules={[Navigation, Pagination]}
                                className="section4modal-slider"
                                loop={true}
                                navigation={{
                                    nextEl: '.swiper-button-next',
                                    prevEl: '.swiper-button-prev',
                                }}
                            >
                                <div className="swiper-wrapper">
                                    {selectedReview?.images.map((image, index) => (
                                        <SwiperSlide key={index}>
                                            <img src={image} alt={`Review slide ${index + 1}`}/>
                                        </SwiperSlide>
                                    ))}
                                </div>
                                <div className="swiper-button-prev"></div>
                                <div className="swiper-button-next"></div>
                            </Swiper>
                        </div>

                        <div className="review__details">
                            <div className="review__company">
                                <img src={userIcon} className="user" alt="리뷰-유저이미지"/>
                                <p className="company-name">{maskCompanyName(selectedReview?.companyName)}</p>
                            </div>
                            <div className="review-content">
                                <div className="review__title">{selectedReview?.title}</div>
                                <div className="review__context">{selectedReview?.content}</div>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default Section4_Sub;
