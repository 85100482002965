import React, { useState } from "react";
import logoFooter from "./logo-footer.png";
import chevronDown from "./chevron-down.svg";
import "./Footer.css";

const Footer = () => {
    const [isFamilySiteVisible, setIsFamilySiteVisible] = useState(false);

    const toggleFamilySite = () => {
        setIsFamilySiteVisible(!isFamilySiteVisible);
    };

    return (
        <footer>
            <div className="inner inner--footer">
                <img src={logoFooter} alt="하단로고" className="footer-logo" />
                <div className="footer-context">
                    <ul>
                        <li>경기도 안산시 상록구 해양 3로 17 디지털전환 허브 1~5층 스마트제조혁신센터</li>
                        <li>TEL: 031-498-7161 / FAX: 031-498-7114</li>
                        <li>E-mail: smic@smic.kr</li>
                    </ul>
                    <div className="copyright">copyright&copy; smic. all right reserved.</div>
                </div>
                <div className="family-site">
                    {/* 패밀리사이트 버튼 */}
                    <div className="family-site__btn" onClick={toggleFamilySite}>
                        <p className="play-bold">family site</p>
                        <img src={chevronDown} className={`chevron-down ${isFamilySiteVisible ? 'rotate' : ''}`} alt="chevron-down" />
                    </div>
                    {/* 패밀리사이트 버튼 클릭 시 나타나는 그룹 */}
                    <ul className={`family-site__group ${isFamilySiteVisible ? 'show' : ''}`}>
                        <li><a href="https://www.smic.kr/" target="_blank" >스마트제조혁신센터</a></li>
                        <li><a href="https://www.5g-sfa.com" target="_blank" rel="noopener noreferrer">5G-SFA</a></li>
                        <li><a href="https://www.iansan.net/" target="_blank" rel="noopener noreferrer">안산시</a></li>
                        <li><a href="https://www.gg.go.kr/" target="_blank" rel="noopener noreferrer">경기도</a></li>
                        <li><a href="https://www.gtp.or.kr" target="_blank" rel="noopener noreferrer">경기테크노파크</a></li>
                        <li><a href="https://www.kiat.or.kr" target="_blank" rel="noopener noreferrer">한국산업기술진흥원</a></li>
                        <li><a href="https://kosmia.or.kr/" target="_blank" rel="noopener noreferrer">한국스마트제조산업협회</a></li>
                        <li><a href="https://hp.sbc.or.kr" target="_blank" rel="noopener noreferrer">중소기업진흥공단(SBC)</a></li>
                        <li><a href="https://www.ksa.or.kr" target="_blank" rel="noopener noreferrer">한국표준협회(KSA)</a></li>
                        <li><a href="https://www.kpc.or.kr/" target="_blank" rel="noopener noreferrer">한국생산성본부(KPC)</a></li>
                        <li><a href="https://www.kitech.re.kr" target="_blank" rel="noopener noreferrer">한국생산기술연구원(KITECH)</a></li>
                        <li><a href="https://www.keti.re.kr" target="_blank" rel="noopener noreferrer">전자부품연구원(KETI)</a></li>
                        <li><a href="https://www.korcham.net" target="_blank" rel="noopener noreferrer">대한상공회의소</a></li>
                        <li><a href="https://www.smart-factory.kr" target="_blank" rel="noopener noreferrer">스마트공장 추진단</a></li>
                        <li><a href="https://www.motie.go.kr" target="_blank" rel="noopener noreferrer">산업통상자원부</a></li>
                    </ul>
                </div>
            </div>
        </footer>
    );
};

export default Footer;
