import React, {useEffect} from "react";
import closeImg from '../../images/icons/close.png';
import {insertUseCase, updateUseCase} from '../usecase/UseCaseSend'; // insertUseCase 함수 import

const Modal = ({modalOpen, toggleModal, isEditing, formData, handleChange, handleFileChange, handleDelete,resetForm}) => {

    const handleSubmit = async (isEditing) => {
        const formDataToSend = new FormData();
        formDataToSend.append("ucTitle", formData.ucTitle);
        formDataToSend.append("companyName", formData.companyName);
        formDataToSend.append("inquiryDt", formData.inquiryDt);
        formDataToSend.append("svcDt", formData.svcDt);
        // showYn 값에 따라 Y 또는 N으로 설정
        formDataToSend.append("showYn", formData.showYn === "Y" ? "Y" : "N");
        // deleteYn 값 설정 (기본값은 'N'으로 설정)
        formDataToSend.append("deleteYn", "N");
        formDataToSend.append("ucContent", formData.ucContent);

        // 파일 객체 추가
        if (formData.ucImg) {
            formDataToSend.append("ucImg", formData.ucImg);
        }
        if (formData.ucImg1) {
            formDataToSend.append("ucImg1", formData.ucImg1);
        }
        if (formData.ucImg2) {
            formDataToSend.append("ucImg2", formData.ucImg2);
        }

        try {
            if (isEditing) {
                formDataToSend.append("seq", formData.seq ? formData.seq.toString() : ""); // seq 값 추가
                // 수정 요청
                const result =await updateUseCase(formDataToSend); // 수정 요청 함수 호출
                if(result==200){
                    alert("수정 성공")
                    window.location.reload();
                }else{
                    alert("수정 실패")
                }
            } else {
                // 등록 요청
               const result= await insertUseCase(formDataToSend); // 등록 요청 함수 호출
                if(result==200){
                    alert("등록 성공")
                    window.location.reload();
                }else{
                    alert("등록 실패")
                }
            }
            toggleModal(); // 모달 닫기
            resetForm(); // 폼 초기화
        } catch (error) {
            console.error("Error:", error.message);
        }
    };

    useEffect(() => {
        const handleKeyDown = (event) => {
            if (event.key === "Escape" && modalOpen) { // 모달이 열려 있을 때만 ESC 키로 닫기
                toggleModal(); // ESC 키를 눌렀을 때 모달 닫기
            }
        };

        // 이벤트 리스너 추가
        window.addEventListener("keydown", handleKeyDown);

        // 컴포넌트 언마운트 시 이벤트 리스너 제거
        return () => {
            window.removeEventListener("keydown", handleKeyDown);
        };
    }, [modalOpen, toggleModal]); // modalOpen 의존성 추가

    return (
        modalOpen && (
            <div className="modal-container admin-modal">
                <div className="page-title">
                    <div className={'modal-title'}>
                        활용 사례 {isEditing ? "수정" : "등록"}
                    </div>
                    <div className={'modal-exitIcon'}>
                        <img
                            src={closeImg}
                            className="modal-close-button"
                            alt="close-btn"
                            onClick={toggleModal}
                        />
                    </div>
                </div>
                <form className="registration-form" onSubmit={(e) => e.preventDefault()}>
                    {/* 제목 입력 필드 */}
                    <div className="admin-form-group">
                        <label htmlFor="service-title" className="form-label">
                            제목<span> *</span>
                        </label>
                        <div className="input-wrapper">
                            <input
                                type="text"
                                className="form-input modalTitle"
                                name="ucTitle"
                                value={formData.ucTitle}
                                onChange={handleChange}
                                placeholder="제목"
                            />
                        </div>
                    </div>
                    {/* 회사명 입력 필드 */}
                    <div className="admin-form-group">
                        <label htmlFor="company-name" className="form-label">
                            회사명<span> *</span>
                        </label>
                        <div className="input-wrapper">
                            <input
                                type="text"
                                className="form-input"
                                name="companyName"
                                value={formData.companyName}
                                onChange={handleChange}
                                placeholder="회사명"
                            />
                        </div>
                    </div>
                    {/* 문의일자 및 서비스 완료일 입력 필드 */}
                    <div className="admin-form-group form-group-small">
                        <label htmlFor="inquiry-date" className="form-label">
                            문의 일자<span> *</span>
                        </label>
                        <div className="input-wrapper">
                            <input
                                type="date"
                                className="form-input form-input-small"
                                name="inquiryDt"
                                value={formData.inquiryDt}
                                onChange={handleChange}
                            />
                        </div>
                        <label htmlFor="service-end-date" className="form-label">
                            서비스 완료일<span> *</span>
                        </label>
                        <div className="input-wrapper">
                            <input
                                type="date"
                                className="form-input form-input-small"
                                name="svcDt"
                                value={formData.svcDt}
                                onChange={handleChange}
                            />
                        </div>
                    </div>
                    {/* 대표 이미지 파일 입력 필드 */}
                    <div className="admin-form-group">
                        <label htmlFor="main-image" className="form-label">
                            대표이미지<span> *</span>
                        </label>
                        <div className="input-wrapper">
                            <input
                                type="file"
                                className="form-input-file"
                                name="ucImg"
                                onChange={handleFileChange}
                            />
                        </div>
                    </div>
                    {/* 추가 이미지 파일 입력 필드 */}
                    <div className="admin-form-group">
                        <label htmlFor="extra-image-1" className="form-label">
                            추가이미지 1
                        </label>
                        <div className="input-wrapper">
                            <input
                                type="file"
                                className="form-input-file"
                                name="ucImg1"
                                onChange={handleFileChange}
                            />
                        </div>
                    </div>
                    <div className="admin-form-group">
                        <label htmlFor="extra-image-2" className="form-label">
                            추가이미지 2
                        </label>
                        <div className="input-wrapper">
                            <input
                                type="file"
                                className="form-input-file"
                                name="ucImg2"
                                onChange={handleFileChange}
                            />
                        </div>
                    </div>
                    {/* 노출 설정 */}
                    <div className="admin-form-group">
                        <label className="form-label">
                            노출 설정<span> *</span>
                        </label>
                        <div className="radio-group">
                            <input
                                type="radio"
                                name="showYn"
                                value="Y" // Y 값으로 설정
                                checked={formData.showYn === "Y"} // showYn이 Y일 때 선택됨
                                onChange={handleChange}
                                className="form-radio"
                            />
                            <label htmlFor="show" className="radio-label">노출</label>
                            <input
                                type="radio"
                                name="showYn"
                                value="N" // N 값으로 설정
                                checked={formData.showYn === "N"} // showYn이 N일 때 선택됨
                                onChange={handleChange}
                                className="form-radio"
                            />
                            <label htmlFor="hide" className="radio-label">비노출</label>
                        </div>
                    </div>

                    {/* 사례 내 입력 필드 */}
                    <div className="admin-form-group form-gruop-textarea">
                        <label htmlFor="case-details" className="form-label">사례 내용<span> *</span></label>
                        <div className="input-wrapper">
                            <textarea
                                className="form-textarea"
                                placeholder="사례 내용"
                                name="ucContent"
                                value={formData.ucContent}
                                onChange={handleChange}
                            ></textarea>
                        </div>
                    </div>
                    {/* 버튼들 */}
                    {isEditing ? (
                        <div className="form-actions-edit">
                            <button type="button" className="btn-modal btn-delete"
                                    onClick={() => handleDelete(formData.seq)}>활용사례 삭제
                            </button>
                            <button type="button" className="btn-modal btn-submit"
                                    onClick={() => handleSubmit(true)}>변경사항 저장하기
                            </button>
                        </div>
                    ) : (
                        <div className="form-actions-regist">
                            <button type="button" className="btn-modal btn-submit"
                                    onClick={() => handleSubmit(false)}>등록
                            </button>
                            <button type="button" className="btn-modal btn-cancel" onClick={toggleModal}>취소</button>
                        </div>
                    )}
                </form>
            </div>
        )
    );
};

export default Modal;
