import React, { useState } from "react";
import "./Section2.css"; // 스타일 시트 경로 수정
import checkIcon from '../../../../images/mainpage/section2/sec2_slide_check.png'; // 체크 아이콘 경로 수정

const Section2 = () => {
    const [currentSlide, setCurrentSlide] = useState(0);

    const slides = [
        {
            serviceNum: "service 1",
            title: "제품 디자인 / 모델링",
            features: [
                {
                    name: "스케치 → 3D모델링",
                    desc: "고객의 마음속에 있는 기능과 구조를 3D 모델링으로 현실화합니다."
                },
                {
                    name: "레이저 판재 커팅",
                    desc: "고에너지의 레이저를 이용한 판재 커팅 서비스를 제공합니다."
                },
                {
                    name: "CNC 가공",
                    desc: "금속 및 비철금속, 수지 가공 서비스를 제공합니다."
                }
            ]
        },
        {
            serviceNum: "service 2",
            title: "AI 기반 모델 최적화",
            features: [
                {
                    name: "디자인 최적화",
                    desc: "기능과 성능을 유지하며 최적의 Volume으로 디자인을 최적화합니다."
                },
                {
                    name: "금형설계 최적화",
                    desc: "금형의 구조 및 재질을 추천하고 선 설계를 통한 정확한 견적을 제시합니다."
                }
            ]
        },
        {
            serviceNum: "service 3",
            title: "시제품 제작",
            features: [
                {
                    name: "금형 제작",
                    desc: "제품 대량 생산을 위한 금형을 제작해드립니다."
                },
                {
                    name: "제품 사출",
                    desc: "금형을 이용해 제품을 사출하고 사출된 제품을 제공합니다."
                },
                {
                    name: "3D 프린팅",
                    desc: "보유하신 3D모델링 파일을 3D프린터를 통해 출력해드립니다."
                }
            ]
        },
        {
            serviceNum: "service 4",
            title: "가공 / 커팅",
            features: [
                {
                    name: "CNC 가공",
                    desc: "금속 및 비철금속, 수지 가공 서비스를 제공합니다."
                },
                {
                    name: "레이저 판재 커팅",
                    desc: "고에너지의 레이저를 이용한 판재 커팅 서비스를 제공합니다."
                }
            ]
        }
    ];

    const handleSlideChange = (index) => {
        setCurrentSlide(index);
    };

    return (
        <div className="section sec2">
            <div className="inner section__inner sec2__inner">
                {/* 페이지 설명 */}
                <div className="subtitle sec2__subtitle">
                    <div className="page-name">about service</div>
                    <div className="sec2_desc_wrapper">
                        <div className="sec2_desc_box">
                            <div className="sec2_desc sec2_desc_maintxt">
                                <p>내가 <span className="deco-navy">원하는 제품</span>을 <span className="deco-navy">어떠한 방법으로 제작</span>할 것인가에 따라 <span className="deco-orange">가격은 천차만별</span>로 달라지게 됩니다.</p>
                            </div>
                            <div className="sec2_sub_text">
                                SMIC는 제품의 설계와 제작과정에 최고의 결과물을 도출해드리고자 아래와 같은 서비스를 제공합니다.
                            </div>
                        </div>
                        <div className="sec2_desc sec2_small">
                            <span className="deco-orange">비전문가</span>인 당신에게 <span className="deco-navy">확실한 가이드</span>와 <span className="deco-navy">놀라운 가격</span>을 제공해 드리겠습니다.
                        </div>
                    </div>
                </div>

                {/* 컨텐츠 */}
                <div className="sec2__contents-wrapper">
                    {/* 슬라이드 */}
                    <div className="sec2_slide_container">
                        {slides.map((slide, index) => (
                            <div
                                className={`sec2_slide ${index === currentSlide ? 'curr' : ''}`}
                                key={index}
                                onClick={() => handleSlideChange(index)} // 클릭 시 슬라이드 변경
                            >
                                <div className="slide__top">
                                    <div className="service service_num">{slide.serviceNum}</div>
                                    <div className="service service_title">{slide.title}</div>
                                </div>
                                <div className="slide__bottom">
                                    {slide.features.map((feature, featureIndex) => (
                                        <div className="service__feature" key={featureIndex}>
                                            <div className="feature__name">
                                                <img src={checkIcon} alt="리스트 마커" />
                                                <p>{feature.name}</p>
                                            </div>
                                            <div className="feature__desc">{feature.desc}</div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        ))}
                    </div>
                    {/* 슬라이드 나브 */}
                    <div className="sec2_slide_nav">
                        {slides.map((_, index) => (
                            <span
                                key={index}
                                className={`nav-bullet ${index === currentSlide ? 'nav-bullet-active' : ''}`}
                                onClick={() => handleSlideChange(index)} // 클릭 시 슬라이드 변경
                            ></span>
                        ))}
                    </div>

                    <div className="sec2_desc sec2_wide">
                        <span className="deco-orange">비전문가</span>인 당신에게 <span className="deco-navy">확실한 가이드</span>와 <span className="deco-navy">놀라운 가격</span>을 제공해 드리겠습니다.
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Section2;
