import api from "../../api/api";
import axios, { isAxiosError } from 'axios';
import authStorage from "../../storages/authStorage";

const commonURL = '/usecase';

let cachedToken = null; // 토큰 캐시 변수

const getToken = async () => {
    // 캐시된 토큰이 없으면 새로 가져옴
    if (!cachedToken) {
        cachedToken = await authStorage.getAccessToken();
    }
    return cachedToken;
};

/* 활용사례 목록 조회 (로그인용) */
export const getList = async (page = 1, size = 10) => {
    try {
        const token = await getToken(); // 토큰 가져오기

        const response = await api.get(`${commonURL}`, {
            params: { page, size },
            headers: {
                Authorization: `Bearer ${token}`
            }
        });

        return response.data;
    } catch (error) {
        if (isAxiosError(error)) {
            throw new Error(error.response.data.message || 'GetUseCaseList failed');
        }
        throw new Error('An unexpected error occurred');
    }
};

/* 활용사례 목록 조회(관리자용) */
export const GetUseCaseList = async (dto) => {
    try {
        const token = await getToken(); // 토큰 가져오기
        const response = await api.get(`${commonURL}/admin`, {
            headers: {
                Authorization: `Bearer ${token}`
            },
            data: dto // dto를 본문으로 전송
        });
        return response.data;
    } catch (error) {
        if (isAxiosError(error)) {
            throw new Error(error.response.data.message || 'GetUseCaseList failed');
        }
        throw new Error('An unexpected error occurred');
    }
};

/* 활용사례 등록 */
export const insertUseCase = async (formDataToSend) => {
    try {
        const token = await getToken(); // 토큰 가져오기

        const files = [
            formDataToSend.get("ucImg"),
            formDataToSend.get("ucImg1"),
            formDataToSend.get("ucImg2"),
        ];

        for (let i = 0; i < files.length; i++) {
            const file = files[i];
            if (file) {
                const singleFileData = new FormData();
                singleFileData.append("file", file);

                const uploadResponse = await api.post(`/file/fileupload`, singleFileData, {
                    headers: {
                        Authorization: `Bearer ${token}`,
                        'Content-Type': 'multipart/form-data'
                    },
                });

                const fileData = uploadResponse.data.data;
                formDataToSend.set(`ucImg${i > 0 ? i : ''}`, fileData.seq);
            } else {
                formDataToSend.set(`ucImg${i > 0 ? i : ''}`, '');
            }
        }

        const finalResponse = await api.post(`${commonURL}/insert`, formDataToSend, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        });

        return finalResponse.data.resCode;

    } catch (error) {
        if (isAxiosError(error)) {
            throw new Error(error.response.data.message || 'insertUseCase failed');
        }
        throw new Error('An unexpected error occurred');
    }
};

/* 활용사례 수정 */
export const updateUseCase = async (formDataToSend) => {
    try {
        const token = await getToken(); // 토큰 가져오기

        const files = [
            formDataToSend.get("ucImg"),
            formDataToSend.get("ucImg1"),
            formDataToSend.get("ucImg2"),
        ];

        for (let i = 0; i < files.length; i++) {
            const file = files[i];
            if (file) {
                const singleFileData = new FormData();
                singleFileData.append("file", file);
                const uploadResponse = await api.post(`/file/fileupload`, singleFileData, {
                    headers: {
                        Authorization: `Bearer ${token}`,
                        'Content-Type': 'multipart/form-data',
                    },
                });
                const fileData = uploadResponse.data.data;
                formDataToSend.set(`ucImg${i > 0 ? i : ''}`, fileData.seq);
            } else {
                formDataToSend.set(`ucImg${i > 0 ? i : ''}`, '');
            }
        }

        const response = await api.put(`${commonURL}/update`, formDataToSend, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        });
        return response.data.resCode;
    } catch (error) {
        if (isAxiosError(error)) {
            throw new Error(error.response.data.message || 'updateUseCase failed');
        }
        throw new Error('An unexpected error occurred');
    }
};

/* 활용사례 삭제 */
export const DeleteUseCase = async (seq) => {
    const token = await getToken(); // 토큰 가져오기

    try {
        const response = await api.put(`${commonURL}/delete?seq=${seq}`, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        });
        return response.data.resCode;
    } catch (error) {
        if (isAxiosError(error)) {
            throw new Error(error.response?.data || 'DeleteUseCase failed');
        }
        throw new Error('An unexpected error occurred');
    }
};
