// src/storages/authStorage.js
import axios from 'axios';

const ACCESS_TOKEN = 'ACCESS_TOKEN';
const REFRESH_TOKEN = 'REFRESH_TOKEN';

// const baseURL = 'http://192.168.0.5:8912'; // 로그인 요청을 보낼 API 주소
// const baseURL = 'http://192.168.33.6:8912'; // 로그인 요청을 보낼 API 주소
const baseURL = process.env.REACT_APP_BASE_URL;

const authStorage = {
    // 액세스 토큰을 가져오는 메서드
    async getAccessToken() {
        try {
            // 로그인 요청을 서버로 전송
            const response = await axios.post(`${baseURL}/user/login`, {
                userId: 'smicadmin',
                userPw: 'smicadmin',
            });
            // 서버로부터 성공적인 응답 수신 시
            if (response.status === 200) {
                window.localStorage.removeItem(REFRESH_TOKEN);
                // 응답 데이터에서 토큰 추출
                const {accessToken, refreshToken} = response.data.data.token || {};
                // 토큰이 존재하지 않을 경우 예외 처리
                if (!accessToken || !refreshToken) {
                    throw new Error('토큰이 응답에 포함되지 않았습니다.');
                }
                // 토큰을 로컬 스토리지에 저장
                this.setAccessToken(accessToken);
                // this.setRefreshToken(refreshToken);
                // 액세스 토큰 반환
                return accessToken;
            } else {
                throw new Error('로그인에 실패하였습니다.');
            }
        } catch (err) {
            // 오류 처리 및 상세 로그 출력
            console.error("서버 응답 오류: ", err.response?.data || err.message);
            throw new Error('Access Token을 가져오기에 실패하였습니다: ' + err.message);
        }
    },

    // 액세스 토큰을 로컬 스토리지에 저장
    setAccessToken(accessToken) {
        try {
            window.localStorage.setItem(ACCESS_TOKEN, accessToken); // JSON.stringify 제거
        } catch (err) {
            console.error("Access Token 저장 실패: ", err);
            throw new Error('Access Token 저장에 실패하였습니다.');
        }
    },

    // 리프레시 토큰을 로컬 스토리지에서 가져옴
    getRefreshToken() {
        try {
            const rawData = window.localStorage.getItem(REFRESH_TOKEN);
            return rawData; // JSON.parse 제거
        } catch (err) {
            console.error("Refresh Token 불러오기 실패: ", err);
            throw new Error('Refresh Token 불러오기에 실패하였습니다.');
        }
    },

    // 리프레시 토큰을 로컬 스토리지에 저장
    setRefreshToken(refreshToken) {
        try {
            window.localStorage.setItem(REFRESH_TOKEN, refreshToken); // JSON.stringify 제거
        } catch (err) {
            console.error("Refresh Token 저장 실패: ", err);
            throw new Error('Refresh Token 저장에 실패하였습니다.');
        }
    },
    // 로컬 스토리지에서 토큰 제거
    clear() {
        try {
            window.localStorage.removeItem(ACCESS_TOKEN);
            window.localStorage.removeItem(REFRESH_TOKEN);
        } catch (err) {
            console.error("토큰 제거 실패: ", err);
        }
    },
};

export default authStorage;
